<template>
  <section class="products">
    <v-flex xs12 class="ctk-top-banner">
      <v-img
        v-if="banner"
        :src="banner"
        class="ctk-top-banner"
        >
      </v-img>
    </v-flex>
    <v-container  grid-list-md text-center class="ctk-div"  id="ctk_top" style="padding-top:10px !important;">
      <v-layout wrap mt-4 mt-md-12>
        <v-flex xs12 md3 lg3 pa-2 mb-4
          v-for="(c, i) in cases"
          :key="i"
        >
          <a :href="'/case/' + c.id">
            <v-img
              :src="c.img"
              aspect-ratio="1"
              class="ctk-product-img"
            ></v-img>
            <p class="ctk-case-title">{{c.title}}</p>
            <!-- <p v-for="(attr, i) in c.attr" :key="i"  class="ctk-case-attr">{{attr.attr_name}}：{{attr.attr_value}}</p> -->
          </a>
        </v-flex>
      </v-layout>
      <template>
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="totalPage"
            circle
          ></v-pagination>
        </div>
      </template>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    cases: null,
    catId: 17,
    banner: null,
    pageId: 3,
    totalPage: 1,
    page: 1
  }),
  created () {
    document.querySelector('#menu_cases').classList.add('v-btn--active')
    this.getCases(this.page, this.catId)
  },
  mounted () {
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.banner = val.banner[this.pageId][0].img
      },
      deep: true
    },
    page: {
      handler: function (val, oldVal) {
        document.querySelector('#ctk_top').scrollIntoView(true)
        this.getCases(val, this.catId)
      },
      deep: true
    }
  },
  computed: {
  },
  methods: {
    getCases (page, cid) {
      this.https.get('cases', { page: page, cid: cid, pageSize: 12 }).then(response => {
        if (response.code === 0) {
          this.cases = response.data.data
          this.totalPage = response.data.last_page
          this.page = page
        }
      })
    }
  }
}
</script>
<style>
.ctk-case-title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  line-height: 64px;
  color: #000000;
  margin-bottom: 0 !important;
}
.ctk-case-attr {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  color: #000000;
  margin-bottom: 0 !important;
}
</style>
